import axios from '@/plugins/axios'

export default {
  async list(productId, data) {
    const params = new URLSearchParams(data).toString()
    return await axios.get(`products/${productId}/images?${params}`)
  },

  async get(productId, id) {
    return await axios.get(`products/${productId}/images/${id}`)
  },

  async insert(productId, data) {
    return await axios.post(
      `products/${productId}/images`,
      data,
      {
        headers: {
        'Content-Type': 'multipart/form-data',
      }
      },
    )
  },

  async update(productId, id, data) {
    return await axios.put(`products/${productId}/images/${id}`, data)
  },

  async delete(productId, id) {
    return await axios.delete(`products/${productId}/images/${id}`)
  },
}
