var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"700px","scrollable":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","dense":"","color":"grey lighten-2"}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.productId)?_c('span',{staticClass:"body-2"},[_c('i',[_vm._v("#"+_vm._s(_vm.product.id))])]):_vm._e()]),_c('v-spacer'),_c('v-toolbar-items',[(_vm.loading)?_c('v-progress-linear',{attrs:{"color":"primary","absolute":"","bottom":"","indeterminate":""}}):_vm._e(),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.show = false}}},[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-close')}})],1)],1)],1),_c('v-card-text',{staticClass:"pt-4 text-center"},[_c('validation-observer',{ref:"observer"},[_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"vid":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"hide-details":"auto","label":"Nome","disabled":_vm.loading,"error-messages":errors},model:{value:(_vm.product.name),callback:function ($$v) {_vm.$set(_vm.product, "name", $$v)},expression:"product.name"}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"vid":"description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"hide-details":"auto","label":"Descrição","disabled":_vm.loading,"error-messages":errors},model:{value:(_vm.product.description),callback:function ($$v) {_vm.$set(_vm.product, "description", $$v)},expression:"product.description"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{staticClass:"text-left"},[_c('v-label',[_vm._v("Imagens")]),(_vm.images.length <= 0)?_c('v-row',[_c('v-col',{staticClass:"caption"},[_c('i',[_vm._v("Nenhuma imagem cadastrada")])])],1):_vm._e(),(_vm.productId)?[(_vm.loadingImages)?_c('v-progress-circular',{staticClass:"ml-2",attrs:{"indeterminate":"","size":"16","width":"2","color":"primary"}}):_vm._e(),_c('v-item-group',{staticClass:"mt-2"},[_c('v-container',{staticClass:"pa-0"},[_c('v-row',_vm._l((_vm.images),function(image){return _c('v-col',{key:image.id,attrs:{"md":"2"}},[_c('v-item',[_c('v-col',[_c('v-img',{attrs:{"contain":"","src":_vm.imageName(image.image)}}),_c('v-btn',{attrs:{"x-small":"","color":"error","icon":"","absolute":"","top":""},on:{"click":function($event){return _vm.deleteImage(image.id)}}},[_c('v-icon',{domProps:{"textContent":_vm._s(_vm.iconDeleteImage)}})],1)],1)],1)],1)}),1)],1)],1),_c('v-btn',{staticClass:"mt-3",attrs:{"small":"","outlined":"","color":"success"},on:{"click":function () { return (_vm.showDialogAddImage = true); }}},[_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s('mdi-plus')}}),_vm._v(" Adicionar imagem ")],1)]:[_c('v-row',[_c('v-col',[_c('span',{staticClass:"caption"},[_c('i',[_vm._v("*Salve o produto para poder cadastrar as imagens")])])])],1)]],2)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),(!_vm.loading)?_c('v-btn',{attrs:{"color":"success","outlined":"","loading":_vm.loadingSave},on:{"click":_vm.save}},[_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s('mdi-content-save')}}),_vm._v(" Salvar ")],1):_vm._e()],1)],1),_c('dialog-product-add-image',{attrs:{"product-id":_vm.productId},on:{"save":function () {
        _vm.showDialogAddImage = false
        _vm.loadImages()
      }},model:{value:(_vm.showDialogAddImage),callback:function ($$v) {_vm.showDialogAddImage=$$v},expression:"showDialogAddImage"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }