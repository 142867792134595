<template>
  <v-dialog v-model="show" max-width="700px" scrollable>
    <v-card>
      <v-toolbar flat dense color="grey lighten-2">
        <v-toolbar-title>
          {{ title }}
          <span v-if="productId" class="body-2">
            <i>#{{ product.id }}</i>
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-progress-linear
            v-if="loading"
            color="primary"
            absolute
            bottom
            indeterminate
          />
          <v-btn icon small @click="show = false">
            <v-icon v-text="'mdi-close'" />
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pt-4 text-center">
        <validation-observer ref="observer">
          <v-row>
            <v-col>
              <validation-provider v-slot="{ errors }" vid="name">
                <v-text-field
                  v-model="product.name"
                  hide-details="auto"
                  label="Nome"
                  :disabled="loading"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <validation-provider v-slot="{ errors }" vid="description">
                <v-textarea
                  v-model="product.description"
                  hide-details="auto"
                  label="Descrição"
                  :disabled="loading"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-left">
              <v-label>Imagens</v-label>

              <v-row v-if="images.length <= 0">
                <v-col class="caption">
                  <i>Nenhuma imagem cadastrada</i>
                </v-col>
              </v-row>

              <template v-if="productId"
                ><v-progress-circular
                  v-if="loadingImages"
                  indeterminate
                  size="16"
                  width="2"
                  color="primary"
                  class="ml-2"
                />
                <v-item-group class="mt-2">
                  <v-container class="pa-0">
                    <v-row>
                      <v-col md="2" v-for="image in images" :key="image.id">
                        <v-item>
                          <v-col>
                            <v-img contain :src="imageName(image.image)"> </v-img>
                            <v-btn
                              x-small
                              color="error"
                              icon
                              absolute
                              top
                              @click="deleteImage(image.id)"
                            >
                              <v-icon v-text="iconDeleteImage" />
                            </v-btn>
                          </v-col>
                        </v-item>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-item-group>
                <v-btn
                  small
                  outlined
                  class="mt-3"
                  color="success"
                  @click="() => (showDialogAddImage = true)"
                >
                  <v-icon left v-text="'mdi-plus'" />
                  Adicionar imagem
                </v-btn>
              </template>
              <template v-else>
                <v-row>
                  <v-col>
                    <span class="caption"
                      ><i
                        >*Salve o produto para poder cadastrar as imagens</i
                      ></span
                    >
                  </v-col>
                </v-row>
              </template>
            </v-col>
          </v-row>
        </validation-observer>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="!loading"
          color="success"
          outlined
          :loading="loadingSave"
          @click="save"
        >
          <v-icon v-text="'mdi-content-save'" left />
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>

    <dialog-product-add-image
      v-model="showDialogAddImage"
      :product-id="productId"
      @save="
        () => {
          showDialogAddImage = false
          loadImages()
        }
      "
    />
  </v-dialog>
</template>

<script>
import productsApi from '@/api/products'
import productsImagesApi from '@/api/products-images'

export default {
  components: {
    DialogDelete: () => import('@/components/admin/dialog/DialogDelete'),
    DialogProductAddImage: () =>
      import('@/components/admin/dialog/products/DialogProductAddImage'),
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    productId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      loading: false,
      loadingImages: false,
      loadingDeleteImage: false,
      loadingSave: false,
      showDialogAddImage: false,
      product: {},
      images: [],
    }
  },

  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },

    title() {
      return this.productId ? 'Editar' : 'Adicionar'
    },

    iconDeleteImage() {
      return this.loadingDeleteImage ? 'mdi-dots-circle' : 'mdi-delete'
    },
  },

  watch: {
    show(val) {
      if (!val) return

      this.load()
      this.loadImages()
    },
  },

  methods: {

    async load() {
      try {
        this.product = {}
        if (!this.productId) return

        this.loading = true
        const response = await productsApi.get(this.productId)
        this.product = response.data.product
      } catch (e) {
        this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
      } finally {
        this.loading = false
      }
    },

    async loadImages() {
      try {
        this.images = []
        if (!this.productId) return

        this.loadingImages = true
        const response = await productsImagesApi.list(this.productId)
        this.images = response.data.images
      } catch (e) {
        this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
      } finally {
        this.loadingImages = false
      }
    },

    async save() {
      try {
        this.loadingSave = true

        this.$refs.observer.reset()

        if (this.productId) {
          await productsApi.update(this.productId, this.product)
        } else {
          await productsApi.insert(this.product)
        }

        this.show = false
        this.$snackbar.show({
          color: 'success',
          message: this.productId
            ? this.$messages._('update_success')
            : this.$messages._('new_success'),
        })
        this.$emit('save')
      } catch (e) {
        if (e.response.status === 422) {
          this.$refs.observer.setErrors(this.$apiError.fieldsValidation(e))
          return
        }

        this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
      } finally {
        this.loadingSave = false
      }
    },

    async deleteImage(imageId) {
      try {
        this.loadingDeleteImage = true
        await productsImagesApi.delete(this.productId, imageId)
        this.loadImages()
      } catch (e) {
        this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
      } finally {
        this.loadingDeleteImage = false
      }
    },

    imageName(name) {
      return `${process.env.VUE_APP_API}public/products/${name}`
    },
  },
}
</script>
